import { useTranslation } from "react-i18next";
import "./index.css";


function GetEducation() {
    const { t } = useTranslation();
    const myEducation = t('educations', { returnObjects: true });
    const returnElement = [];

    for (let index = 0; index < myEducation.length; index++) {
        returnElement.push(
            <div key={index} className="education--loops">
                <div className="education--header">
                    <div className="education--title">
                        <h4>{t(`educations.${index}.course`)}</h4>
                        <p className="college--info">{t(`educations.${index}.college`)}</p>
                        <p className="education--period">{t(`educations.${index}.period`)}</p>
                    </div>
                </div>
                <div className="education--specs">
                </div>
            </div>
        )
    }
    return returnElement;
}

export default GetEducation;