import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import usFlagIcon from "../../assets/icons/united-states.png";
import brFlagIcon from "../../assets/icons/brazil.png";
import itFLagIcon from "../../assets/icons/italy.png";
import frFlagIcon from "../../assets/icons/france.png";

const TextLanguage = () => {
    const en = "en";
    const pt = "pt";
    const it = "it";

    const localStorageLanguage = localStorage.getItem("language") || en;

    const { i18n } = useTranslation();
    const [enOpacity, setEnOpacity] = useState(1);
    const [ptOpacity, setPtOpacity] = useState(1);
    const [itOpacity, setItOpacity] = useState(1);
    const [language, setLanguage] = useState(en);
    const [checked, setChecked] = useState(localStorageLanguage);
    const invisible = (value) => {
        return checked === value ? true : false;
    }
    const onToggle = (value) => {
        setChecked(value);
        setLanguage(value);

    }

    useEffect(() => {
        setLanguage(localStorageLanguage);
    }, [])

    useEffect(() => {
        localStorage.setItem("language", language);
        i18n.changeLanguage(language);
    }, [language, setLanguage]);


    return <div className="language-container">
        <div className="toggle">
            {!invisible(en) && <div style={{ opacity: enOpacity }} className="toggle-option" >
                <img src={usFlagIcon} onMouseOver={() => setEnOpacity(0.3)} onMouseOut={() => setEnOpacity(1)} onClick={() => { onToggle(en); setEnOpacity(1); }} alt="us-flag" />
            </div>}
            {!invisible(pt) && <div style={{ opacity: ptOpacity }} className="toggle-option" >
                <img src={brFlagIcon} onMouseOver={() => setPtOpacity(0.3)} onMouseOut={() => setPtOpacity(1)} onClick={() => { onToggle(pt); setPtOpacity(1); }} alt="us-flag" />
            </div>}
            {!invisible(it) && <div style={{ opacity: itOpacity }} className="toggle-option" >
                <img src={itFLagIcon} onMouseOver={() => setItOpacity(0.3)} onMouseOut={() => setItOpacity(1)} onClick={() => { onToggle(it); setItOpacity(1); }} alt="us-flag" />
            </div>}
        </div>
    </div>

}

export default TextLanguage;