const translation = {
    translation: {
        under_construction: "Em Construção!",
        home: {
            description: "Bem-vindo ao meu website/blog! Este é um espaço reservado em que eu compartilho conhecimentos e curiosidades sobre as minhas paixões: código, games e linguística!",
        },
        pt_BR: "pt_BR",
        en_US: "en",
        it_IT: "it",
        job_description: "Desenvolvedor de Software",
        about: "Sobre",
        blog: "Blog",
        contact: "Contato",
        city_country: "Manaus, Brasil",
        profile: "Perfil",
        profile_description: "Sou bacharel em Ciência da Computação pela Universidade de Brasília, apaixonado por jogos, lingüística e tecnologia. Entusiasta das Metodologias Ágeis e poliglota que gosta de aprender novas linguagens naturais e de programação. Atualmente ansioso para aprender novas linguagens e cultura e também procurando oportunidades de Engenharia de Jogos.",
        professional_exp: "Experiência Profissional",
        jobs: [{
            job_position: "Engenheiro de Games",
            company_name: "Wildlife Studios",
            location: "São Paulo, Brazil (Remoto)",
            period: "Jun 2022 - Nov 2022",
            descriptions: [{
                0: "Engenheiro de jogos responsável pelo suporte e desenvolvimento de novas funcionalidades para jogos ao vivo da Wildlife, tanto no lado do cliente como no lado do servidor",
                1: "Trabalhei no Cocos2D 'Block Craft 3D', um jogo voxel, com +1,0M de usuários diários ativos, sobre construir o que o jogador quiser",
                2: "Trabalhei também no Unity3D 'Suspects', um jogo de Dedução Social, com +250k usuários diários ativos, com bate-papo de voz integrado e sistema de progressão adequado",
                3: "Uso de frameworks Scrum e Kanban a fim de implementar boas práticas de gestão de equipe que contribuam para um desenvolvimento mais ágil",
                4: "As aplicações utilizam Cocos2D (C++), Unity3D (C#), Node.JS, GoLang (Backend), Redis e MongoDB como tecnologias",
            }],
        },
        {
            job_position: "Desenvolvedor de Software Unity",
            company_name: "Sidia Instituto de Ciência e Tecnologia",
            location: "Manaus, Brasil",
            period: "Abr 2021 - Atualmente",
            descriptions: [{
                0: "Dev da equipe de Realidade Aumentada do Instituto Sidia de Ciência e Tecnologia. Responsável pelo desenvolvimento de alguns projetos confidenciais para vários clientes como a Samsung, desenvolvendo aplicações para óculos de realidade virtual e realidade aumentada",
                1: "Além de Unity3D para os projetos de AR/VR, C++ também foi utilizado para atender às demandas do Samsung Watch3 (Tizen OS)",
                2: "Uso de frameworks Scrum e Kanban a fim de implementar boas práticas de gestão de equipe que contribuam para um desenvolvimento mais ágil",
                3: "As aplicações utilizam, entre outras tecnologias confidenciais, Unity3D, C++, o Microsoft Mixed Reality Toolkit, SQL, Confluence e Jenkins como tecnologias",
            }],
        },
        {
            job_position: "Desenvolvedor Fullstack",
            company_name: "Grupo ICTS",
            location: "Brasília, Brasil",
            period: "Ago 2020 - Mar 2021",
            descriptions: [{
                0: "Dev Team na equipe de Seguros de Vida do SICOOB. Responsável por desenvolver novas funcionalidades para as diversas aplicações que envolvam seguros de vida humana.",
                1: "Utilização do framework de desenvolvimento ágil Scrum de forma a implementar boas práticas de gerenciamento de equipe que contribuem para desenvolvimento mais ágil.",
                2: "As aplicações de Seguros de Vida do SICOOB utilizam Java EE, Spring, Hibernate, DB2, Angular, Adobe Flex, JBoss, Maven e Jenkins como tecnologias e ferramentas.",
            }],
        },
        {
            job_position: "Desenvolvedor Fullstack",
            company_name: "Globalweb Outsourcing",
            location: "Brasília, Brasil",
            period: "Fev 2020 - Ago 2021",
            descriptions: [{
                0: "Dev na Caixa Econômica Federal no projeto web da loteria do Brasil. O site tinha cerca de um ano no momento e já teve mais de dois milhões de pessoas cadastradas, o que corresponde a 80 milhões de apostas na loteria",
                1: "No frontend, trabalhei implementando novas funcionalidades no site tais como notificações, refactor do carrinho de compras e a adição de nova modalidade de apostas",
                2: "Trabalhei também implementando recursos para uma aplicação web para gerente, para que eles pudessem ver estatísticas e análises da aplicação da loteria",
                3: "No backend, trabalhei na integração do backend das loterias online com o API de pagamento do Mercado Pago, um serviço similar ao PayPal",
                4: "Trabalhei na definição do esquema de banco de dados do aplicativo (banco de dados DB2)",
                5: "Trabalhei também na integração do aplicativo online através das filas MQ da IBM com um sistema já com mais de 30 anos desenvolvido em COBOL",
                6: "A aplicação web da loteria usa Java, EJB, Ibm MQ, EasyRest, AngularJS, DB2, JBoss, MainFrame e OpenJPA como tecnologias",
            }],
        },
        {
            job_position: "Assistente de Pesquisa - Desenvolvedor Fullstack",
            company_name: "Instituto Brasileiro de Informação em Ciência e Tecnologia - IBICT",
            location: "Brasília, Brasil",
            period: "Set 2019 - Fev 2020",
            descriptions: [{
                0: "Trabalhei no Instituto Brasileiro de Informação em Ciência e Tecnologia analisando informações de arquitetura do Visão (Sistema Aberto de Observatório para Visualização de Informações), hospedado em visao.ibict.br",
                1: "Meu principal objetivo foi propor um modelo de migração de dados para a infraestrutura da aplicação e provar a sua viabilidade, além de buscar por falhas de persistências de dados.",
                2: "O projeto Visão utiliza HTML, JavaScript, AngularCLI, PHP and MySQL como ferramentas de desenvolvimento.",
            }],
        }],
        education_title: "Educação",
        educations: [{
            course: "Pós-Graduação em Desenvolvimento de Sistemas em Java",
            college: "Faculdade Unyleya",
            location: "Brasília, Brasil",
            period: "Mar 2019 - Nov 2019"
        },
        {
            course: "Bacharelado em Ciência da Computação",
            college: "Universidade de Brasília - UnB",
            location: "Brasília, Brasil",
            period: "Ago 2014 - Dez 2018"
        }],
        language_title: "Idiomas",
        portuguese: "Português (Nativo)", 
        english: "Inglês (Avançado)",
        italian: "Italiano (Avançado)",
        french: "Francês (Básico)",
    }
};

export default translation;