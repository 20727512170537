import { useTranslation } from "react-i18next";
import "./index.css"

function GetLanguages() {
    const { t } = useTranslation();

    return (
        <div className="languages--container">
            <div className="languages">
                <p className="language--bullet">{'\u2022 '}</p>
                <p className="language--item">{t("portuguese")}</p>
            </div>
            <div className="languages">
                <p className="language--bullet">{'\u2022 '}</p>
                <p className="language--item">{t("english")}</p>
            </div>
            <div className="languages">
                <p className="language--bullet">{'\u2022 '}</p>
                <p className="language--item">{t("italian")}</p>
            </div>
            <div className="languages">
                <p className="language--bullet">{'\u2022 '}</p>
                <p className="language--item">{t("french")}</p>
            </div>
        </div>
    );

}

export default GetLanguages;