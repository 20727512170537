const translation = {
    translation: {
        under_construction: "In Costruzione!",
        home: {
            description: "Benvenuto nel mio sito/blog! Questo è un posto in cui condivido conoscenze e curiosità sulle mie passioni: codice, games e linguistica!",
        },
        pt_BR: "pt_BR",
        en_US: "en",
        it_IT: "it",
        job_description: "Sviluppatore Software",
        about: "About",
        blog: "Blog",
        contact: "Contatto",
        city_country: "Manaus, Brasile",
        profile: "Profilo",
        profile_description: "Ho una laurea in Informatica presso l'Università di Brasilia e sono appassionato di giochi, linguistica e tecnologia. Appassionato di metodologie agili e poliglotta che ama imparare nuovi linguaggi naturali e di programmazione. Attualmente desideroso di imparare nuove lingue e culture e anche alla ricerca di opportunità di Game Engineering.",
        professional_exp: "Esperienza Professionale",
        jobs: [{
            job_position: "Ingegnere di Gioco",
            company_name: "Wildlife Studios",
            location: "Sao Paulo, Brasile (Remoto)",
            period: "Jun 2022 - Nov 2022",
            descriptions: [{
                0: "Ingegnere di gioco responsabile del supporto e dello sviluppo di nuove funzionalità per i giochi Wildlife live sia sul lato client che su quello server",
                1: "Ho lavorato in Cocos2D 'Block Craft 3D', un gioco voxel da +1,0 milioni di utenti attivi al giorno che consiste nel costruire qualsiasi cosa il giocatore desideri",
                2: "Ho lavorato anche in Unity3D 'Suspects', un gioco di deduzione sociale da +250k utenti attivi al giorno con chat vocale integrata e un sistema di progressione adeguato",
                3: "Utilizzo dei framework Scrum e Kanban per implementare buone pratiche di gestione del team che contribuiscono a uno sviluppo più agile",
                4: "Le applicazioni utilizzano come tecnologie Cocos2D (C++), Unity3D (C#), Node.JS, GoLang (Backend), Redis e MongoDB",
            }],
        },
        {
            job_position: "Sviluppatore Unity",
            company_name: "Sidia Istituto di Scienza e Tecnologia",
            location: "Manaus, Brasile",
            period: "Apr 2021 - Jun 2022",
            descriptions: [{
                0: "Team di sviluppo presso il team di realtà aumentata del Sidia Institute of Science and Technology. Responsabile dello sviluppo di alcuni progetti riservati per vari clienti, come Samsung, sviluppando applicazioni per occhiali di realtà virtuale e realtà aumentata",
                1: "Oltre a Unity Engine per AR/VR, è stato utilizzato anche C++ per soddisfare le richieste di Samsung Watch3 (Tizen OS)",
                2: "Uso del framework Scrum per implementare buone pratiche di gestione del team che contribuiscono a uno sviluppo più agile",
                3: "Le applicazioni utilizzano, tra le altre tecnologie riservate, Unity3D, C++, Microsoft Mixed Reality Toolkit, SQL, Confluence e Jenkins come tecnologie",
            }],
        },
        {
            job_position: "Sviluppatore software Fullstack",
            company_name: "Grupo ICTS",
            location: "Brasilia, Brasile",
            period: "Ago 2020 - Mar 2021",
            descriptions: [{
                0: "Team Dev presso il team assicurazioni vita della SICOOB. È responsabile dello sviluppo di nuove funzionalità per le varie applicazioni relative alle assicurazioni sulla vita.",
                1: "Utilizzazione del framework di sviluppo agile Scrum per implementare buone pratiche di gestione del team che contribuiscono a uno sviluppo più agile.",
                2: "L'applicazione di assicurazione sulla vita utilizza come tecnologie e framework Java EE, Spring, Hibernate, DB2, Angular, Adobe Flex, JBoss, Maven e Jenkins.",
            }],
        },
        {
            job_position: "Sviluppatore software Fullstack",
            company_name: "Globalweb Outsourcing",
            location: "Brasilia, Brasile",
            period: "Feb 2020 - Ago 2021",
            descriptions: [{
                0: "Dev Team della Caixa Econômica Federal per la soluzione web della lotteria brasiliana. Il sito web aveva circa un anno di vita e aveva già più di due milioni di persone registrate, il che corrisponde a 80 milioni di scommesse alla lotteria",
                1: "Nel frontend, ho lavorato all'implementazione di nuove funzionalità del sito, come le notifiche, il refactor del carrello e l'aggiunta di nuove modalità di scommessa",
                2: "Ho lavorato anche all'implementazione di funzionalità per un'applicazione web per i manager, in modo che potessero vedere statistiche e analisi dall'applicazione della lotteria",
                3: "Nel backend, ho lavorato all'integrazione del backend delle lotterie online con le API di pagamento di Mercado Pago, un servizio simile a PayPal",
                4: "Ho lavorato alla definizione dello schema del database dell'applicazione (database DB2)",
                5: "Ho lavorato anche all'integrazione dell'applicazione online attraverso le code MQ di IBM con un sistema di 30 anni fa sviluppato in COBOL",
                6: "L'applicazione web della lotteria utilizza come tecnologie Java, EJB, Ibm MQ, EasyRest, AngularJS, DB2, JBoss, MainFrame e OpenJPA",
            }],
        },
        {
            job_position: "Assistente di Ricerca - Sviluppatore Fullstack",
            company_name: "Istituto Brasiliano di Informazione in Scienza e Tecnologia - IBICT",
            location: "Brasilia, Brasile",
            period: "Set 2019 - Feb 2020",
            descriptions: [{
                0: "Presso l'Istituto brasiliano di scienza e tecnologia dell'informazione ho lavorato analizzando l'architettura dell'informazione di VISAO (Open Observatory System for Information Visualization), ospitato su visao.ibict.br.",
                1: "Il mio obiettivo principale era proporre un modello di migrazione dei dati per l'infrastruttura dell'applicazione e dimostrarne la fattibilità. Mi sono anche occupato dei problemi di persistenza dei dati nel database VISAO.",
                2: "Il progetto VISAO ha utilizzato HTML, JavaScript, Angular, PHP e MySQL come strumenti di sviluppo.",
            }],
        }],
        education_title: "Education",
        educations: [{
            course: "Post-laurea in Sviluppo di Sistemi Con Java",
            college: "Faculdade Unyleya",
            location: "Brasilia, Brasile",
            period: "Mar 2019 - Nov 2019"
        },
        {
            course: "Laurea Triennale in Informatica",
            college: "Università di Brasilia - UnB",
            location: "Brasilia, Brasile",
            period: "Ago 2014 - Dic 2018"
        }],
        language_title: "Lingue",
        portuguese: "Portoghese (Nativo)", 
        english: "Inglese (Avanzato)",
        italian: "Italiano (Avanzato)",
        french: "Francese (Basico)",
    }
};

export default translation;