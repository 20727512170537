const translation = {
    translation: {
        under_construction: "Under Construction!",
        home: {
            description: "Welcome to my website/blog! This is a place where I share knowledge and curiosities about my passions: code, games and linguistics!",
        },
        pt_BR: "pt_BR",
        en_US: "en",
        it_IT: "it",
        job_description: "Software Developer",
        about: "About",
        blog: "Blog",
        contact: "Contact",
        city_country: "Manaus, Brazil",
        profile: "Profile",
        profile_description: "I have a Bachelor's Degree in Computer Science from University of Brasilia and I'm passionate about gaming, linguistics and technology. Agile Methodologies enthusiast and polyglot who enjoys learning new natural and programming languages. Currently eager to learn new languages and culture and also looking for Game Engineering opportunities.",
        professional_exp: "Professional Experience",
        jobs: [{
            job_position: "Game Engineer",
            company_name: "Wildlife Studios",
            location: "Sao Paulo, Brazil (Remote)",
            period: "Jun 2022 - Nov 2022",
            descriptions: [{
                0: "Game engineer responsible for supporting and developing new features for Wildlife live games in both client and server sides.",
                1: "I've worked in Cocos2D 'Block Craft 3D', a +1.0M daily active users voxel game about building whatever the player wants.",
                2: "I've worked also in Unity3D 'Suspects', a +250k daily active users Social Deduction game with integrated voice chat and proper progression system.",
                3: "Use of Scrum and Kanban frameworks in order to implement good team management practices that contribute to more agile development.",
                4: "The applications use Cocos2D (C++), Unity3D (C#), Node.JS, GoLang (Backend), Redis and MongoDB as technologies.",
            }],
        },
        {
            job_position: "Unity Software Developer",
            company_name: "Sidia Institute of Science and Technology",
            location: "Manaus, Brazil",
            period: "Apr 2021 - Jun 2022",
            descriptions: [{
                0: "Dev Team at Sidia Institute of Science and Technology's Augmented Reality team. Responsible for developing some confidential projects for various clients such Samsung by developing application for virtual reality glasses and augmented reality.",
                1: "Beside Unity Engine for AR/VR, C++ was also used in order to fulfill demands for Samsung Watch3 (Tizen OS).",
                2: "Use of Scrum framework in order to implement good team management practices that contribute to more agile development.",
                3: "The applications use, among other confidential technologies, Unity3D, C++, Microsoft's Mixed Reality Toolkit, SQL, Confluence and Jenkins as technologies.",
            }],
        },
        {
            job_position: "Fullstack Software Developer",
            company_name: "Grupo ICTS",
            location: "Brasilia, Brazil",
            period: "Aug 2020 - Mar 2021",
            descriptions: [{
                0: "Dev Team at SICOOB's life insurance team. Responsible for developing new features for the various applications involving human life insurance.",
                1: "Use of Scrum agile development framework in order to implement good team management practices that contribute to more agile development.",
                2: "The life insurance application uses Java EE, Spring, Hibernate, DB2, Angular, Adobe Flex, JBoss, Maven and Jenkins as technologies and frameworks.",
            }],
        },
        {
            job_position: "Fullstack Software Developer",
            company_name: "Globalweb Outsourcing",
            location: "Brasilia, Brazil",
            period: "Feb 2020 - Aug 2021",
            descriptions: [{
                0: "Dev Team at Caixa Econômica Federal on the web solution of Brazil's lottery. The website was about one year old at the moment and have already had more than two million people registered, which it corresponds to 80 million lottery bets.",
                1: "In the frontend, I've worked implementing new features to the website such notifications, shopping cart refactor and the addition to new bet modality.",
                2: "I've worked also implementing features for a web application for manager, so they could see statistics and analytics from the lotteria application.",
                3: "In the backend, I've Worked in the integration of the online lotteries backend with the payment API from Mercado Pago, a service similar to PayPal.",
                4: "I've Worked in definition of the application's database schema (DB2 database).",
                5: "I've Worked also in the integration of the online application through IBM's MQ queues with an already 30 years old system developed in COBOL",
                6: "The lottery web application uses Java, EJB, Ibm MQ, EasyRest, AngularJS, DB2, JBoss, MainFrame and OpenJPA as technologies.",
            }],
        },
        {
            job_position: "Research Asssistant - Fullstack Developer",
            company_name: "Brazilian Institute of Information in Science and Technology - IBICT",
            location: "Brasilia, Brazil",
            period: "Sep 2019 - Feb 2020",
            descriptions: [{
                0: "At the Brazilian Institute of Information Science and Technology I worked by analysing the information architecture of VISAO (Open Observatory System for Information Visualization), hosted at visao.ibict.br.",
                1: "My main goal there was to propose a data migration model for the application infrastructure and to prove it's viability. I also looked up for data persistence failures in the VISAO database.",
                2: "The VISAO project used HTML, JavaScript, Angular, PHP and MySQL as development tools",
            }],
        }],
        education_title: "Education",
        educations: [{
            course: "Postgraduate in Systems Development with Java",
            college: "Faculdade Unyleya",
            location: "Brasilia, Brazil",
            period: "Mar. 2019 - Nov. 2019"
        },
        {
            course: "Bachelor's Degree in Computer Science",
            college: "Universidade de Brasília - UnB",
            location: "Brasilia, Brazil",
            period: "Aug. 2014 - Dec. 2018"
        }],
        language_title: "Languages",
        portuguese: "Portuguese (Native)", 
        english: "English (Advanced)",
        italian: "Italian (Advanced)",
        french: "French (Basic)",
    }
};

export default translation;