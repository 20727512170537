import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import pt from '../locales/portuguese_pt/translation';
import en from '../locales/english_en/translation';
import it from '../locales/italian_it/translation';


i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    debug: false,

    ns: ['translation'],
    defaultNS: 'translation',
    resources: {
        "pt": pt,
        "en": en,
        "it": it,
    },
    keySeparator: '.',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;