import './App.css';
import Home from './pages/home';
import Curriculum from './pages/curriculum';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import TextLanguage from './components/textLanguage';


function App() {
  return (<Router>
      <TextLanguage />
      <Switch>
        <Route path="/curriculum">
          <CurriculumPage />
        </Route>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch>
  </Router>)
}

function HomePage() {
  return <Home />;
}

function CurriculumPage() {
  return <Curriculum />;
}

export default App;
