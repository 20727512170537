import "./index.css";
import { useTranslation } from 'react-i18next';


const WriteDescription = (array, index) => {
    const returnElement = [];
    let descriptions = array.array;
    for (let index = 0; index < 10; index++) {
        let element = descriptions[index];
        if (element !== undefined)
            returnElement.push(<div key={index} className="job--description">
                <p className="job--bullet">{'\u2022 '}</p>
                <p className="job--description-item">{element}</p>
            </div>)
    }
    return returnElement
}

function GetJobs() {
    const { t } = useTranslation();
    const myJobs = t('jobs', { returnObjects: true });
    const returnElement = [];

    for (let index = 0; index < myJobs.length; index++) {
        let description = t(`jobs.${index}.descriptions`, { returnObjects: true });
        returnElement.push(<div key={index} className="jobs--loop">
            <div  className="job--header">
                <div className="job--title">
                    <h3>{t(`jobs.${index}.job_position`)}</h3>
                    <p className="company--info">{t(`jobs.${index}.company_name`)}</p>
                </div>
                <div className="job--specs">
                    <p className="job--location">{t(`jobs.${index}.location`)}</p>
                    <p className="job--period">{t(`jobs.${index}.period`)}</p>
                </div>
            </div>
            <div className="job--infos">
                <WriteDescription array={description[0]} index={index} />
            </div>

        </div>)
    }
    return returnElement;
}

export default GetJobs;